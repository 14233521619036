import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Success from "./Components/Success";
import Roundslider from "./Components/Roundslider";
import Roted3d from "./Components/Roted3d";
import Footer from "./Components/Footer";
import Headernav1 from "./Components/Headernav1/Headernav1";
import Choice from "./Components/Choice/Choice";
import Next from "./Components/Next/Next";
import Ntarpay from "./Pages/Home/Ntarpay/Ntarpay";
import Bbpscroll from "./Pages/Home/Ntarpay/Bbpscroll";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Headernav1" element={<Headernav1 />} />
        <Route path="/Choice" element={<Choice />} />
        <Route path="/Next" element={<Next />} />
        <Route path="/Ntarpay" element={<Ntarpay />} />
        {/* <Route path="/Bbpscroll " element={<Bbpscroll />} /> */}
       
     
        {/* <Route path="/" element={<Footer/>} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
