import React, { useState, useEffect } from "react";
import "./style/Header.css";
import mainlogo from "../assets/Image/mainlogo.png";
import menu from "../assets/Image/menu.webp";
import Headernav1 from "./Headernav1/Headernav1";

const Header = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showMobNav, setShowMobNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (popoverOpen || menuOpen || isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [popoverOpen, menuOpen, isOpen]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768 || (width >= 768 && width <= 1024)); // Includes iPads in the range
    };

    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="desktop_cont">
        <div className="header-top">
          <div className="header-menu">
            <span className="active_tab">
              {isMobile ? " Business" : "NTAR"}
            </span>
            <span
              style={{ color: "white", fontSize: "15px", fontWeight: "500" }}
            >
              Customer
            </span>
            <span
              style={{ color: "white", fontSize: "15px", fontWeight: "500" }}
            >
              Community
            </span>
          </div>
        </div>
      </div>

      <div className="phone_con">
        <div className="orange_header">
          <div className="orange_row">
            <div className="black_active">
              <p style={{ margin: "8px" }}>Ntar</p>
            </div>

            <div className="black_active_cus">
              <p style={{ margin: "8px" }}>Business</p>
            </div>

            <p style={{ margin: "8px" }}>Customer</p>
            <p style={{ margin: "8px" }}>Community</p>
          </div>
        </div>
      </div>

      <div className={`header-container ${isScrolled ? "scrolled" : ""}`}>
        <div className="header-bottom">
          <a style={{color:'white'}} href="/" className="header-logo">
            <img src={mainlogo} alt="Small Icon" className="small-icon" />
            <span className="logo-icon"></span> NTAR{" "}
            <span className="bizz" style={{ marginLeft: "10px" }}>
              Bizz
            </span>
          </a>

          <div className="header-links">
            <span onClick={togglePopover}>Features</span>
            <span>Use Case</span>
            <span>Industries</span>
            <span>Why NTAR</span>
            <span>Stories</span>
            <span>Careers</span>
          </div>

          {popoverOpen && (
            <>
              <div className="overlay" onClick={togglePopover}></div>
              <div className="popover-container">
                <div className="popover-content">
                  <Headernav1 />
                </div>
              </div>
            </>
          )}

          <div className="header-actions">
            <button className="header-btn">Let's NTAR</button>
            <div className="theme-toggle">
              <div className="hamburger-menu" onClick={toggleMenu}>
                <img src={menu} alt="Menu" />
              </div>
            </div>
          </div>
        </div>

        {menuOpen && (
          <>
            <div className="overlay" onClick={toggleMenu}></div>
            <div className="dropdown-scrollable">
              <div className="header-dropdown">
                <span onClick={toggleDropdown}>
                  Features
                  {isOpen ? (
                    <svg width="18" height="12" viewBox="0 0 16 10" fill="none">
                      <path
                        d="M13.5684 0.985899L15.5 2.91749L11.3048 7.17302C11.3048 7.17302 9.76559 9.01407 7.98491 9.01407C6.20423 9.01407 4.72535 7.17302 4.72535 7.17302L0.5 2.91749L2.40141 1.01608L7.38574 6.00041C7.71639 6.33106 8.2527 6.33034 8.58246 5.9988L13.5684 0.985899Z"
                        fill="#3203EA"
                      />
                    </svg>
                  ) : (
                    <svg width="18" height="12" viewBox="0 0 16 10" fill="none">
                      <path
                        d="M2.4316 9.0141L0.5 7.08251L4.69522 2.82698C4.69522 2.82698 6.23441 0.985931 8.01509 0.985931C9.79577 0.985931 11.2746 2.82698 11.2746 2.82698L15.5 7.08251L13.5986 8.98392L8.61426 4.99959C8.28361 4.66894 7.7473 4.66966 7.41754 5.0012L2.4316 9.0141Z"
                        fill="#3203EA"
                      />
                    </svg>
                  )}
                </span>

                {isOpen && <Headernav1 />}

                <span>Use Case</span>
                <span>Industries</span>
                <span>Why NTAR</span>
                <span>Stories</span>
                <span>Careers</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
