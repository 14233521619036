import React from "react";
import "./Ntarpay.css";
import Header from "../../../Components/Header.jsx";
import Footer from "../../../Components/Footer.jsx";
import qr from "../../../assets/Image/newscanner.webp";
import phone from "../../../assets/Image/phone.png";
import sideleft from "../../../assets/Image/sideleft.png";
import sideright from "../../../assets/Image/sideright.png";
import blackappstore from "../../../assets/Image/blackappstore.png";
import blackplaystore from "../../../assets/Image/blackplaystore.png";
import Bbpscroll from "./Bbpscroll.jsx";

import managebills from "../../../assets/Image/managebills.png";
import paybills from "../../../assets/Image/pay.webp";
import split from "../../../assets/Image/split.webp";
import billrem from "../../../assets/Image/billrem.webp";
import goodbye from "../../../assets/Image/goodbye.webp";
import manage from "../../../assets/Image/manage.webp";
import charts from "../../../assets/Image/charts.webp";
import rackup from "../../../assets/Image/rackup.webp";
import orange from "../../../assets/Image/orange.png";
import blackimg from "../../../assets/Image/blackorimg1.webp";
import sidemobile1 from "../../../assets/Image/sidemobile-1.webp";
import sidemobile2 from "../../../assets/Image/sidemobile-2.webp";

const billData = [
  {
    icon: orange, // Use your actual icon paths
    title: "Manage your Bills and Credit Cards",
    description:
      "Keep close track of all your Bills from your phone. Get an overview of what’s already been paid and what’s due when, so you can control your cash flow.",
  },
  {
    icon:paybills, // Use different icons if you have them
    title: "Pay Bills on the Go",
    description:
      "Make payment check the status of any bill at anytime, anywhere. All from NTAR mobile app.",
  },
  {
    icon: split, // Use different icons if you have them
    title: "Split the Damn Bill",
    description:
      "With NTAR, it’s easy to split your bills and make sure everyone pays what they’re due to. and partial payments give you more flexibility than ever.",
  },
  {
    icon: billrem, // Use different icons if you have them
    title: "Bill Reminder and Notifications",
    description:
      "NTAR will remind your bills are due so you will never miss a bill payment again and stay organized with ease.",
  },
  {
    icon: goodbye, // Use different icons if you have them
    title: "Say goodbye to late fees",
    description:
      "It’s hard to keep track of which bills come monthly, quarterly, yearly and when they are due. Review and schedule bill payments in advance so they are always paid on time and you have on less thing to worry about.",
  },
  {
    icon: manage, // Use different icons if you have them
    title: "Pay multiple bills together",
    description:
      "Save time on making individual payments. Collate your bills and pay them all at once. Your can also pay many bills with single payment method.",
  },
  {
    icon: charts, // Use different icons if you have them
    title: "Charts and Calendar View",
    description:
      "NTAR's charts and calendar view—track payments, due dates, and budgeting all with clear, interactive graphics.",
  },
  {
    icon: rackup, // Use different icons if you have them
    title: "Rack up Rewards.",
    description:
      "Use your credit cards to pay your bills to collect rewards like points, miles and cashback each month on all the bill you have to pay anyway.",
  },

];

const Ntarpay = () => {
  return (
    <div style={{ overflowX: "hidden",background:'white' }}>
      <Header />
      <section className="bbps-hero">
        <div className="bbps-hero-content b">
          <h1>
            Revolutionize your business
            <br /> utility bill payments effortlessly
            <br /> with the NTAR Bizz App.
          </h1>
        </div>
      </section>
      <Bbpscroll />

      <section className="section-3">
        <h2 className="sec3_text">
          A simpler way to pay utility
          <br />
          bills online.
        </h2>
        <p className="the_per_sub_text">
          With NTAR Bizz, you can pay for your business’ phone, wifi, water,
          gas, and electric bills—all in the same place. <br />
          Save time and take care of all your business’ utility bills at once.
        </p>

        <div className="box_div">
          <div className="orange_box">
            <div className="text-side-div">
              <strong className="auto_side_text">
                Automatically get your
                <br /> utility bills paid.
              </strong>
              <p className="auto-sub-text">
                Keeping track of monthly and quarterly bills and their due dates
                can be challenging. Schedule your bills in advance to ensure
                they’re paid on time, giving you one less thing to worry about.
              </p>
            </div>
            <img src={sidemobile1} alt="icon" className="centered_image" />
          </div>
        </div>
        <br />
        <br />
        <div className="box_div">
          <div className="orange_box">
            <div className="text-side-div">
              <strong className="auto_side_text">
              Manage all your bills in<br/> one place.
              </strong>
              <p className="auto-sub-text">
              Manage all your utility bills effortlessly from your phone. View payment history and upcoming due dates to stay in full control of your cash flow.
              </p>
            </div>
            <img src={sidemobile2} alt="icon" className="centered_image" />
          </div>
        </div>
      </section>

      <section className="bbps-bills-section_scroll">
        <h2 style={{ margin: "4px" }}>
          A simpler way to pay utility
          <br />
          bills online.
        </h2>
        <p className="the_per_sub_text">
          With NTAR - Bill Reminder Feature you will never miss a bill again
        </p>
        <div className="bill-container">
          {billData.map((item, index) => (
            <div key={index} className="bill-card">
              <div className="bill-texts">
                <img src={item.icon} alt="icon" className="bill-icon" />
                <h3>{item.title}</h3>
                <p className="bill-subtext">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="black_bg">
        <p className="bbps-tes">
          How <span className="ntar_org_bb"> NTAR</span> Pay Works
        </p>
        <h3>Download Sure, here’s a concise version of how NTAR Pay works</h3>

        <section class="text_round_image">
          <div class="image_container">
            <img src={blackimg} alt="icon" className="centered_image" />

            <p class="top_left">
              <div>
              <svg
                width="60"
                height="60"
                viewBox="0 0 95 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="94.52"
                  height="94.52"
                  rx="19.6917"
                  fill="#FF5614"
                />
                <path
                  d="M50.0081 64.8291H43.276V33.819H37.127L39.8304 27.723H50.0081V64.8291Z"
                  fill="white"
                />
              </svg>
              </div>
              Open the NTAR Mobile App and
              <br /> select Example : "
              <span className="electricity">ELECTRICITY BILL</span>"
            </p>

            <p class="top_right">
              <div className="right-top-text">
              <svg
                width="60"
                height="60"
                viewBox="0 0 95 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="94.52"
                  height="94.52"
                  rx="19.6917"
                  fill="#FF5614"
                />
                <path
                  d="M57.5884 47.4422L47.2517 58.7331H62.9422V64.8291H43.329C40.3075 64.8291 38.7172 63.3979 38.7172 61.2775C38.7172 59.9523 38.9293 59.0511 40.9436 56.8248L52.3935 43.9437C54.5669 41.5583 55.574 39.915 55.574 37.9007C55.574 35.1442 53.5067 33.1299 50.5382 33.1299C47.7287 33.1299 45.5554 34.9322 45.5554 38.1127C45.5554 38.9078 45.6614 39.4909 45.9264 40.074L39.7244 41.9293C39.3003 40.8161 39.0353 39.5439 39.0353 38.2187C39.0353 31.3806 43.9651 27.0869 50.6442 27.0869C57.5353 27.0869 62.3591 31.3806 62.3591 37.6886C62.3591 40.9752 61.0869 43.6256 57.5884 47.4422Z"
                  fill="white"
                />
              </svg>
              </div>
              Enter your unique service number,
              <br /> and click "<span className="electricity">Next.</span>"
            </p>
            <p class="bottom_left">
              <div>
              <svg
                width="60"
                height="60"
                viewBox="0 0 95 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="94.52"
                  height="94.52"
                  rx="19.6917"
                  fill="#FF5614"
                />
                <path
                  d="M55.8468 36.1632L49.6447 42.6833C55.9528 43.6905 59.3984 47.9842 59.3984 53.5501C59.3984 61.2894 53.6204 65.6361 46.5172 65.6361C40.1032 65.6361 35.4914 62.1375 34.0072 56.2536L40.2622 54.3983C41.1103 57.8438 43.4957 59.6461 46.6232 59.6461C50.0688 59.6461 52.7723 57.3137 52.7723 53.5501C52.7723 50.2636 49.9098 47.8252 46.0932 47.8252H45.139C43.0717 47.8252 41.5344 46.0759 41.5344 44.4326C41.5344 43.2664 41.9585 42.0472 43.2837 40.828L50.0158 33.8839H35.9685V27.8939H52.6132C55.9528 27.8939 57.9141 29.3781 57.9141 32.0286C57.9141 33.4598 57.5961 34.414 55.8468 36.1632Z"
                  fill="white"
                />
              </svg>
              </div>
              Search for and select your
              <br /> Example : <span className="electricity">
                ELECTRICITY
              </span>{" "}
              operator.
            </p>
            <p class="bottom_right">
            <div className="right-bottom-text">
              <svg
                width="60"
                height="60"
                viewBox="0 0 95 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="94.52"
                  height="94.52"
                  rx="19.6917"
                  fill="#FF5614"
                />
                <path
                  d="M56.278 27.8939V51.8008H61.6319L60.4657 57.6318H56.278V65H49.8639V57.6318H36.9828C34.5974 57.6318 32.5831 56.1475 32.5831 53.5501C32.5831 52.596 32.6891 51.3768 33.6963 49.9985L49.4399 27.8939H56.278ZM49.97 36.3223L39.3682 51.8008H49.97V36.3223Z"
                  fill="white"
                />
              </svg>
              </div>
              Proceed to pay the amount.
              <br /> <span className="electricity">Payment Successful</span>,
              <br /> A confirmation screen will appear.
            </p>
          </div>
        </section>

        <section className="download-section">
          <h2>
            Everything
            <br /> can be better.
          </h2>
          <p>Download NTAR app on your favourite App store</p>
          <img src={qr} alt="QR Code" className="shop-qr-code" />
          <div className="app-buttons">
            <a href="/Comingsoon">
              <img
                src={blackappstore}
                alt="Download on App Store"
                className="app-button"
              />
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://play.google.com/store/apps/details?id=com.NTAR&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={blackplaystore}
                alt="Get it on Google Play"
                className="app-button"
              />
            </a>
          </div>
        </section>
      </section>

      {/* <section className="info-container-main">
        <h2>Your Bills at your fingertips</h2>
        <p className="bbps_sub">
          With BBPS built-in, you know where your money’s going
          <br /> and when it’s going to land.
        </p>
        <div className="info-container">
          <div className="info-box left-box">
            <p className="let_text">Let’s NTAR Now</p>
            <img src={phone} alt="Phone App" className="info-phone-image" />
            <ol className="info-steps">
              <li>
                <strong>Open the NTAR App</strong> - Tap the Bill icon on the
                dashboard screen in the NTAR App.
              </li>
              <li>
                <strong>Select the Service Provider</strong> - Enter the details
                for your Biller. We will fetch your Bill amount.
              </li>
              <li>
                <strong>Get that payment done</strong> - Enter new card details
                to make payment or use and UPI or existing card you have saved
                in the NTAR app
              </li>
              <li>
                <strong>The future's bright</strong> - Once Paid, your biller is
                saved as a linked biller, for easy upcoming payments.
              </li>
            </ol>
          </div>

          <div className="right-column">
            <div className="info-box right-box">
              <div className="qr-section">
                <img src={qr} alt="QR Code" className="qr-code" />
                <h2>Say yes to More</h2>
                <p style={{ textAlign: "left" }}>
                  With NTAR app, every bill payment becomes an opportunity to
                  save. Unlock exclusive discounts and cashback rewards with
                  Bill payment you make. Embrace cashless payment and make every
                  bill payment count with NTAR app.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    fontFamily: "Youth_bold",
                  }}
                >
                  Let’s NTAR now
                </p>
              </div>
            </div>

            <div className="info-box bottom-box">
              <h2 style={{ textAlign: "left" }}>
                Can't see your service provider?
              </h2>
              <p style={{ textAlign: "left" }}>
                Let us know & we will get them added!
              </p>
              <button className="request-provider-button">
                Request service provider
              </button>
            </div>
          </div>
        </div>
      </section> */}

      <setion className="side_drops">
        <div className="sideicon_pay">
          <img src={sideleft} alt="QR Code" className="sideleft" />
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="/Education"
          >
            <p className="p_texts">Pay</p>
          </a>
        </div>

        <div className="sideicon_pay">
          <p className="p_texts">
            Books {"\n"}
            <button className="coming_soon_edu">Coming soon</button>
          </p>
          <img src={sideright} alt="QR Code" className="sideleft" />
        </div>
      </setion>

      <Footer />
    </div>
  );
};

export default Ntarpay;
