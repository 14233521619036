import React, { useEffect, useRef } from 'react';
import './style/Clientsays.css';
import star from '../assets/Image/star.png'; 
import Profile from '../assets/Image/Profile.png';

const Clientsays = () => {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollSpeed = 2; // Scroll step increment for fast scrolling

        const scroll = () => {
            if (scrollRef.current) {
                const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
                scrollRef.current.scrollLeft += scrollSpeed;

                if (scrollRef.current.scrollLeft >= maxScrollLeft) {
                    scrollRef.current.scrollLeft = 0; // Loop back to the start
                }
            }
        };

        const intervalId = setInterval(scroll, 50); // Short interval for faster scrolling

        return () => clearInterval(intervalId);
    }, []);

    const testimonials = [
        {
            id: 1,
            // image: Profile,
            title: 'Exceptional Service!',
            text: `"Since using the invoice management system, our billing process has become incredibly efficient. We've reduced our processing time by 50%, allowing us to focus on growth!"`,
            author: 'Rahul Sharma',
            location: 'Trade Enterprises, Hyderabad, Telangana',
        },
        {
            id: 2,
            // image: Profile,  
            title: 'Cost-Effective Solution',
            text: 'The utility bills payment service has saved us time and money. It’s straightforward and eliminates late fees, making budgeting much easier for our bsiness.',
            author: ' Sita Verma',
            location: 'ManPower Solutions, Hyderabad, Telangana',
        },
        {
            id: 3,
            // image: Profile,
            title: 'Seamless Rent Collection',
            text: 'As a landlord, the rent payment service has been a game changer. I receive payments promptly and can easily track them, which has made my life so mucheasier',
            author: 'Anil Kumar',
            location: ' Realstate Co., Hyderabad, Telangana',
        },

        {
            id: 4,
            // image: Profile,
            title: 'Reliable POS Systems',
            text: 'The POS devices we are using and transformed our sales process. They’re user-friendly and have improved our customer checkout experience signifcantly.',
            author: 'Pooja Singh',
            location: 'Retail Hub, Hyderabad, Telangana',
        },

        {
            id: 5,
            // image: Profile,
            title: 'Inventory Insights',
            text: `With the inventory management solution, we’ve gained better control over our stock. We're now able to minimize waste and optimize our ordering process.`,
            author: 'Vikram Mehta',
            location: 'Tech Goods, Hyderabad, Telangana',
        },

        {
            id: 6,
            // image: Profile,
            title: 'Enhanced Customer Engagement',
            text: 'The customer management tools have allowed us to personalize our interactions, leading to stronger relationships and increased customer oyalty.',
            author: 'Nisha Gupta',
            location: 'Service Experts, Hyderabad, Telangana',
        },
        {
            id: 7,
            // image: Profile,
            title: 'Financial Clarity',
            text: 'Money management tools have given us a clearer picture of our finances. We can track expenses effortlessly and make informed decisions for ourfuture.',
            author: ' Karan Reddy',
            location: ' FinTech Innovations, Hyderabad, Telangana',
        },
        {
            id: 8,
            // image: Profile,
            title: 'Simplified Bill Payments',
            text: '"Using BBPS has made paying multiple bills straightforward. I love having everything in one place, making financial management so much impler!',
            author: 'Sneha Patel',
            location: 'Family Home Services, Hyderabad, Telangana',
        },
        {
            id: 9,
            // image: Profile,
            title: 'Comprehensive Expense Tracking',
            text: '"The expenses management system is fantastic. It has streamlined our reporting process, making it easy to stay on budget and analyze spending patterns.',
            author: ' Rajesh Bhatia',
            location: 'Manufacturing Cor, Hyderabad, Telangana',
        },
        {
            id: 10,
            // image: Profile,
            title: 'Game Changer for Small Businesses',
            text: `As a small business owner, these services have been a game changer. They've simplified so many aspects of my operations, allowing me to focus on growth and customer satisfction.`,
            author: 'Divya Joshi',
            location: 'Artisan Craft, Hyderabad, Telangana',
        },
    ];

    // Repeat the testimonials array multiple times for continuous scrolling
    const repeatedTestimonials = Array(7).fill(testimonials).flat();

    return (
        <div className="clientsays-container">
        <h2 className="clientsays-title">What Our Clients Say</h2>
        <p className="clientsays-subtitle">"Don't just take our word for it—discover how The NTAR Bizz App is transforming lives for others!"</p>
        
        {/* New parent container for controlling width */}
        <div className="scrolling-container">
            <div className="scrolling-content" ref={scrollRef}>
                {repeatedTestimonials.map((testimonial, index) => (
                    <div className="testimonial-card" key={index}>
                        <div className="star-row">
                            {[...Array(5)].map((_, i) => (
                                <img src={star} alt="Star" className="star-image" key={`star-${index}-${i}`} />
                            ))}
                        </div>
                        <div className="testimonial-text">
                            <h3>{testimonial.title}</h3>
                            <p>{testimonial.text}</p>
                            <div className="testimonial-author-container">
                                {/* <img src={testimonial.image} alt={testimonial.author} className="testimonial-image" /> */}
                                <div className="testimonial-author">
                                    <p><strong>{testimonial.author}</strong></p>
                                    <span className="testimonial-location">{testimonial.location}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
    
    );
};

export default Clientsays;
