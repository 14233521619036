import React, { useRef } from 'react';
import './style/ScrollFeature.css';
// import retails from '../assets/Image/retails.png';
import Beauty from '../assets/Image/Beauty.webp';
import Healthcare from '../assets/Image/healthcare.webp';
import Events from '../assets/Image/events.webp';
import Professional from '../assets/Image/professionl.webp';
import Retail from '../assets/Image/retail.webp';
import Travel2 from '../assets/Image/travel2.webp';
import Foodbev from '../assets/Image/foodbev.webp';
import button from '../assets/Image/button.png';
import button1 from '../assets/Image/button21.png';
import leftArrow from '../assets/Image/left-arrow.png'; // Add left arrow image
import rightArrow from '../assets/Image/right-arrow.png'; // Add right arrow image

const ScrollFeature = () => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    }
  };

  return (
    <div >
      <h5 className='path_to'>Path to Powerful</h5>
      <h2 className="header-title">Built your business Special!<br/>
      Like Never Before, Ever After!</h2>
      <div className="buttons-container">
        <img src={button} alt="Build A Store" className="button-image" />
        <img src={button1} alt="Watch Stories" className="button-image1" />
      </div>
      <p className="description">
      Meet the Stars of Business Brilliance! Shine Bright with Features That Elevate Your Business to New Heights – 
      Special, Unique, and Unforgettable!
      </p>
      <div className="scroll-container">
        <img src={leftArrow} alt="Scroll Left" className="arrow left-arrow" onClick={scrollLeft} />
        <div className="scroll-wrapper" ref={scrollRef}>
          <img src={Beauty} alt="Retail" className="retail-image" />
          <img src={Healthcare} alt="Retail" className="retail-image" />
          <img src={Events} alt="Retail" className="retail-image" />
          <img src={Professional} alt="Retail" className="retail-image" />
          <img src={Retail} alt="Retail" className="retail-image" />
          <img src={Travel2} alt="Retail" className="retail-image" />
          <img src={Foodbev} alt="Retail" className="retail-image" />
          {/* <img src={retails} alt="Retail" className="retail-image" />
          <img src={retails} alt="Retail" className="retail-image" />
          <img src={retails} alt="Retail" className="retail-image" />
          <img src={retails} alt="Retail" className="retail-image" />
          <img src={retails} alt="Retail" className="retail-image" /> */}
        </div>
        <img src={rightArrow} alt="Scroll Right" className="arrow right-arrow" onClick={scrollRight} />
      </div>
      <div style={{
        borderBottom: '1px solid orange',
        width: '80%',
        marginLeft: '10%',
        marginTop: '4%'
      }}></div>

    </div>
  );
}

export default ScrollFeature;