import React, { useEffect, useRef } from "react";
import "./Home.css";
import scrolldata from "../../assets/Image/scroll-data.webp";
import scrollinvoice1 from "../../assets/Image/maskgroup.png";
import scrollinvoice2 from "../../assets/Image/electric.png";
import scrollinvoice3 from "../../assets/Image/utilitybills.png";
import scrollinvoice4 from "../../assets/Image/payrent.png";
import scrollinvoice5 from "../../assets/Image/posdev.png";
import scrollinvoice6 from "../../assets/Image/inventory.png";
import scrollinvoice7 from "../../assets/Image/customers.png";
import scrollinvoice8 from "../../assets/Image/dashboard.png";
import scrollinvoice9 from "../../assets/Image/Management.png";
import scrollinvoice10 from "../../assets/Image/bbps.png";
import scrollinvoice11 from "../../assets/Image/rent.png";
import scrollinvoice12 from "../../assets/Image/expenses.png";
import lineup from "../../assets/Image/lineup.webp";
import Features from "../../Components/Features";
import Keyfeatures from "../../Components/Keyfeatures";
import ScrollFeature from "../../Components/ScrollFeature";
import Ownerconnection from "../../Components/Ownerconnection";
import Clientsays from "../../Components/Clientsays";
import Wearehere from "../../Components/Wearehere";
import Footer from "../../Components/Footer";
import Success from "../../Components/Success";
import Header from "../../Components/Header";
import curanimation from "../../assets/Image/cur-animation.png";

const Home = () => {
  const scrollRef = useRef(null);

  // Auto scroll logic
  useEffect(() => {
    const scroll = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: 40, behavior: "smooth" }); // Increased the scroll step
        if (
          scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
          scrollRef.current.scrollWidth
        ) {
          scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
        }
      }
    };

    const intervalId = setInterval(scroll, 30); // Reduced interval for faster scrolling

    return () => clearInterval(intervalId);
  }, []);

  const iconItems = [
    { label: "Invoice", img: scrollinvoice1 },
    { label: "Electricity", img: scrollinvoice2 },
    { label: "Utility Bills", img: scrollinvoice3 },
    { label: "Pay Rent", img: scrollinvoice4 },
    { label: "POS Device", img: scrollinvoice5 },
    { label: "Inventory", img: scrollinvoice6 },
    { label: "Customers", img: scrollinvoice7 },
    { label: "Dashboards", img: scrollinvoice8  },
    { label: "Management", img: scrollinvoice9 },
    { label: "BBPS", img: scrollinvoice10 },
    { label: "Rent", img: scrollinvoice11 },
    { label: "Expenes", img: scrollinvoice12 },
  ];

  // Repeat the iconItems array 7 times
  const repeatedIconItems = Array(100).fill(iconItems).flat();

  return (
    <>
      <div style={{ backgroundColor: "black" }}>
        {/* Home Container */}
        <Header />
        <div className="home-container">
          {/* Top Navigation */}
          <div className="navbar">
            {/* <div className="logo">NTAR</div>
                        <div className="nav-buttons">
                            <button className="btn">BBPS ></button>
                            <button className="btn">Customer ></button>
                        </div> */}
          </div>

          {/* Main Section */}
          <div className="main-section">
            <h1>
              NTAR <span className="highlight">Designed</span> <br />
              for Next <br />
              <span className="highlight">Billion</span> Business
            </h1>

            <div className="icon-scroll" ref={scrollRef}>
              {repeatedIconItems.map((item, index) => (
                <div className="icon-item" key={index}>
                  <img src={item.img} alt={item.label} className="icon-image" />
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Content Section */}
          <div className="content-section">
            <img src={scrolldata} alt="Content Example" />
            <img src={curanimation} alt="Content Example" />
          </div>
        </div>
        <div className="product-lineup-section">
          <h2 className="product-title">
            {" "}
            Discover our product lineup,<br></br> Built to boost your business
            efficiency
          </h2>
          <p className="product-subtitle">
            Step Into a new world that elevates your business to <span className="hundred">100% </span>success!
          </p>

          <div className="product-image-container">
            <img src={lineup} alt="lineup" className="product-image" />
          </div>
        </div>
        <Features />
        <Keyfeatures />
        <ScrollFeature />
        {/* <div style={{marginTop:'-10%'}}>
                <Ownerconnection/></div> */}
        {/* <div style={{ marginBottom: '5%', marginTop: '5%' }}><Success /></div> */}
        <div>
          <Clientsays />
        </div>
        <Wearehere />
        <div style={{ marginTop: "-10%" }}>
          {" "}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
